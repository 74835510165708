.menuBar {
  padding: 0 25px;
  border-bottom: solid 1px #e8e8e8;
  background-color: var(--white);
  text-transform: uppercase;
  position: fixed;
  z-index: 1;
  width: 100%;
}

.logo {
  width: 50px;
  float: left;
  margin-right: 30px;
}

.newLogo {
  width: 50px;
  margin-top: 23px
}

.logoText {
  width: 175px;
  float: left;
  margin-right: 30px;
}

.logoText h2 {
  color: var(--primary-color);
}

.logo a {
  display: inline-block;
  font-size: 20px;
  padding: 19px 20px;
  text-transform: capitalize;
}

.drawer {
  text-transform: uppercase;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: none;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: var(--primary-color);
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: var(--primary-color);
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.barsMenu > span {
  display: block;
}

.leftMenu {
  float: left;
}

.rightMenu {
  float: right;
}

@media (max-width: 1200px) {
  .barsMenu {
    display: inline-block;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo a {
    margin-left: -20px;
    padding: 10px 20px;
  }

}
