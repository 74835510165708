.publicContent {
    padding: 0 5%;
    width: 100vw;
}

@media (max-width: 1200px) {
    .publicContent {
        max-width: 1000px;
    }
}

@media (min-width: 1201px) {
    .publicContent {
        max-width: 80%;
    }
}