.intro {
    padding: 50px 0;
    text-align: center;
}
.intro h1 {
    font-size: calc(var(--font-size-lg)*2);
    text-transform: uppercase;
}

.intro p {
    font-size: calc(var(--font-size-base)*1.3);
    line-height: 30px;
}

.green {
    background-color: var(--primary-color);
    color: var(--white);
    padding: 150px 0;
}

.green-bottom {
    background-color: var(--primary-color);
    background-image: linear-gradient(135deg,var(--primary-color),var(--primary-color-gradient));
    clip-path: polygon(0 0,100% 0,100% 80%,0 100%);
    color: var(--white);
    width: 100%;
    padding-top: 150px ;
    margin-bottom: -50px;
    padding-bottom: 200px;
}

.green-bottom h1 {
    color: var(--white);
}

.green-bottom h2 {
    color: var(--white);
}

.green h1 {
    color: var(--white);
}

.green h2 {
    color: var(--white);
}

.green-top {
    background-color: var(--primary-color);
    background-image: linear-gradient(135deg,var(--primary-color),var(--primary-color-gradient));
    clip-path: polygon(0 15vh,100% 0,100% 100%,0 100%);
    color: var(--white);
    width: 100%;
    padding-bottom: 150px ;
    margin-top: -50px;
    padding-top: 200px;
}

.green-top h1 {
    color: var(--white);
}

.green-top h2 {
    color: var(--white);
}

.white {
    padding: 150px 0;
}